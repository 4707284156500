
export default {
  name: 'Video',
  props: ['preview_image','title','content','video_url'],
  data() {
    return {
      videoToggle: false,
    }
  },
  computed: {
    videoId() {
      return this.video_url.embed_url.substr(
        this.video_url.embed_url.indexOf('v=') + 2,
        this.video_url.embed_url.length
      )
    }
  },
  methods: {
    toggleVideo(){
      this.videoToggle = true
    }
  }
}
